<template>
  <Modal 
    :activeModal="activeModal" 
    :widthModal="450"
    @closeModal="closeModal"
  >
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Anotações
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-col>
          <v-card>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" class="pa-3 pt-0 pb-1 d-flex justify-space-between">
                  <span>Nome:</span> <strong> {{ entity.name }}</strong>
                </v-col>
                <v-col cols="12" class="pa-3 pt-0 pb-1 d-flex justify-space-between">
                  <span>Telefone:</span> <strong> {{ entity.cod | phone2 }} </strong>
                </v-col>
                <!-- <v-col cols="12" class="pa-3 pt-0 pb-1 d-flex justify-space-between">
                  <span>Nome Cliente:</span> <strong> {{ bilhete.NomeCliente }} </strong>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-2">
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" class="pa-3 pt-0 pb-1">
                  <strong> Descrição </strong>
                </v-col>
                <v-col cols="12" class="pa-3 mt-3 pt-0 pb-1 d-flex justify-space-between">
                  <v-textarea
                    v-model="memo"
                    label="Descrição"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

      </v-container>
      <v-card-actions class="modal-cadastro-footer">
        <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
        <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="salvar" class="br-btn" :loading="loading">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
// import { each, size } from 'lodash'
// import validacao from './validacao'

// eslint-disable-next-line
const namespaceStore = ''

export default {
  name: 'ModalJogos',
  mixins: [validationMixin],
  components: {
    Modal: () => import('@/views/components/modal')
  },

  props: {
    activeModal: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    loading: false,
    memo: '',
  }),
  
  computed: {
    
    // ...validacao,
    variables: () => variables,
    // valorPremio: this.bilhete.PossivelRetorno
  },

  methods: {
    ...mapActions('crm', ['salvarMemo']),

    closeModal () {
      this.$emit('closeModal')
    },

    salvar () {
      this.loading = true
      this.salvarMemo({ entityId: this.entity.entity_id, memo: this.memo })
        .then(() => {
          this.$emit('refreshCRM', true)
          successSnackbar('Registro realizado com sucesso')
        })
        .catch((error) => errorSnackbar(error.error))
        .finally(() => this.loading = false)
    }

  },

  beforeDestroy () {
    Events.$off('ganhadores::modal::baixar')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
</style>